@import '../cookie-bar/cookie-bar.scss';




.footer-section {
  padding: 50px 0;

  .menu-item {
    margin-right: 14px;
    //font-size: 14px;
    //padding: 10px;
    display: inline-flex;
    //
    //@media(max-width: 991px) {
    //  padding: 0;
    //  margin: 5px 0;
    //}

    a {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      color: #909294;
      text-decoration: none;
    }
  }
}