@keyframes pulse-animation {
  0%{
    transform: scale(0);
    opacity: 1;
  }
  100%{
    transform: scale(1.8,1.8);
    opacity: 0;
  }
}

.pulse {
  animation-name: pulse-animation;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}


@keyframes changeInvert {
  0% {
    filter: hue-rotate(480deg)

  }
  100% {
    filter: hue-rotate(100deg)
  }
}

.hue-invert {
  animation: changeInvert 10s infinite;
}


@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(-20deg)
  }
  100% {
    transform: rotate(-20deg)
  }
}

.phone-rotate {
  animation: rotate 1s ease-in-out infinite alternate;

}
