@import 'colors.scss';

.transition-03-all {
  transition: 0.3s all;
}

.text-align-justify {
  text-align: justify;
}

.hover-brightness-80:hover {
  filter: brightness(80%);
}

.w-80 {
  width: 80%;
}

.min-h-80 {
  min-height: 80px;
}

.min-h-140 {
  min-height: 140px;
}


.min-h-200 {
  min-height: 200px;
}

.min-h-250 {
  min-height: 250px;
}

.min-h-300 {
  min-height: 300px;
}

.min-h-350 {
  min-height: 350px;
}

.min-h-500 {
  min-height: 500px;
}

.min-h-700 {
  min-height: 700px;
}

.filter-grayscale-100 {
  //filter: grayscale(100%);
}

.transform-rotate-180 {
  transform: rotate(180deg);
}

.border-width-4 {
  border-width: 4px;
}

.border-solid {
  border-style: solid;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-25 {
  border-radius: 25px;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.top-100-procent {
  top: 100%;
}

.grid {
  display: grid;

  &.gap-4 {
    gap: 16px;
  }

  &.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.grid-cols-5 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  &.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  &.grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  &.grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}


.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.opacity-0 {
  opacity: 0;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-08 {
  opacity: 0.8;
}

background-none {
  background: none !important;
}

.background-size-cover {
  background-size: cover;
}

.background-position-center {
  background-position: center;
}

.z-index-minus-3 {
  z-index: -3;
}

.z-index-minus-2 {
  z-index: -2;
}

.z-index-minus-1 {
  z-index: -1;
}

.z-index-plus-1 {
  z-index: 1;
}

::-webkit-scrollbar {
  width: 3px;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
}


#wpadminbar {
  display: none;
}
