.text-gray {
  color: #252525;
}

.bg-gray {
  background-color: #252525;
}

.text-black {
  color: #1d1d1d;
}

.bg-black {
  background-color: #1d1d1d;
}

.text-secondary {
  color: #cdcdcd !important;
}

.text-primary {
  color: #0deb3d !important;
}

.bg-primary {
  background-color: #0deb3d !important;
}

.border-primary {
  border-color: #0deb3d !important;
}

.text-smoke {
  color: #fdfdfd;
}

.bg-smoke {
  background-color: #fdfdfd;
}

.border-black {
  border-color: #111111 !important;
}


.text-success {
  color: #40BB15;
}

.bg-light {
  background-color: #F9F9F9;
}

.text-blue {
  color: #23A6F0;
}

.border-blue {
  border-color: #23A6F0 !important;
}

.text-beam {
  color: #8EC2F2;
}

.bg-yellow {
  background-color: #F3CD03;
}

.text-yellow {
  color: #F3CD03;
}