nav {
  .navbar-brand {
    width: 250px;

    @media(max-width: 991px) {
      width: 180px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    .page_item {
      a {
        display: inline-flex;
        text-transform: uppercase;
        text-decoration: none;
        color: #ffffff;
        padding: 5px 10px;
      }

      &:last-child {
        border: 3px solid #0deb3d;
      }
    }
  }

  .menu-menu-container {
    //margin-left: auto;

    @media(max-width: 991px) {
      //margin: 10px 0;
      margin-top: 20px;
    }


    .menu-item {
      //border: 1px solid red;
      margin-left: 30px;
      display: inline-flex;

      @media(max-width: 991px) {
        margin-left: 0;
      }

      a {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        color: #ffffff;
        text-decoration: none;
        line-height: 8px;
        transition: 0.3s all;

        @media(max-width: 991px) {
          line-height: unset;
        }

        &:hover {
          color: #0deb3d;
        }
      }
    }
  }
}