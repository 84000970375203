@import "../../assets/style/index.scss";
@import '../../node_modules/tiny-slider/dist/tiny-slider.css';
@import "../../node_modules/aos/dist/aos.css";
@import "../../components/navigation/navigation.scss";
@import "../../components/footer/footer.scss";
//@import "../../components/car-card/car-card.scss";
//@import "../../components/cookie-bar/cookie-bar.scss";

main {
  .header-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 20px 0 50px 0;
      min-height: auto;
    }

    .dot {
      width: 40px;
      height: 40px;
      opacity: 0;

      &.dot-1 {
        top: 146px;
        left: -5px;

        @media(max-width: 1399px) {
          top: 122px;
          left: -6px;
        }

        @media(max-width: 1199px) {
          top: 99px;
          left: -9px;
        }
      }

      &.dot-2 {
        top: 137px;
        left: 91px;
        animation-delay: 0.25s;


        @media(max-width: 1399px) {
          top: 114px;
          left: 75px;
        }

        @media(max-width: 1199px) {
          top: 92px;
          left: 60px;
        }
      }

      &.dot-3 {
        top: 172px;
        left: 143px;
        animation-delay: 0.5s;


        @media(max-width: 1399px) {
          top: 143px;
          left: 119px;
        }

        @media(max-width: 1199px) {
          top: 116px;
          left: 96px;
        }
      }

      &.dot-4 {
        top: 74px;
        left: 191px;
        animation-delay: 0.75s;


        @media(max-width: 1399px) {
          top: 60px;
          left: 161px;
        }

        @media(max-width: 1199px) {
          top: 47px;
          left: 130px;
        }
      }
      &.dot-5 {
        top: 232px;
        left: 230px;
        animation-delay: 1s;


        @media(max-width: 1399px) {
          top: 195px;
          left: 194px;
        }

        @media(max-width: 1199px) {
          top: 158px;
          left: 158px;
        }
      }

      &.dot-6 {
        top: 160px;
        left: 290px;
        animation-delay: 1.25s;


        @media(max-width: 1399px) {
          top: 134px;
          left: 245px;
        }

        @media(max-width: 1199px) {
          top: 107px;
          left: 200px;
        }
      }
    }


    //img {
    //  width: 80px;
    //  flex: 0 0 80px;
    //
    //  @media(max-width: 991px) {
    //    width: 40px;
    //    flex: 0 0 40px;
    //  }
    //}

    h1 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }
  }

  .services-section {
    padding: 100px 0 75px 0;

    @media(max-width: 991px) {
      padding: 75px 0 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }

    p {
      @media(max-width: 991px) {
        font-size: 14px;
      }
    }

    @media(max-width: 991px) {
      padding: 50px 0;
      min-height: auto;
    }

    article {
      figure {
        width: 100px;
        height: 100px;
      }
    }
  }

  .work-section {
    padding: 75px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }


    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }

    p {
      @media(max-width: 991px) {
        font-size: 14px;
      }
    }

    @media(max-width: 991px) {
      padding: 50px 0;
      min-height: auto;
    }

    .arrow-left {
      top: -2px;
      right: 95px;

      @media(max-width: 1399px) {
        right: 70px;
      }

      @media(max-width: 1199px) {
        top: 1px;
        right: 49px;
      }
    }
    .arrow-right {
      top: -1px;
      left: 93px;

      @media(max-width: 1399px) {
        left: 70px;
      }

      @media(max-width: 1199px) {
        top: -1px;
        left: 49px;
      }
    }

    .line {
      width: 1150px;
      height: 302px;
      border-bottom: 3px solid #000;
      border-top: 3px solid #000;
      border-right: 3px solid #000;
      right: 0;
      top: 40px;

      @media(max-width: 1399px) {
        height: 326px;
        width: 990px;
      }

      @media(max-width: 1199px) {
        height: 400px;
        width: 850px;
      }

    }

    article {

      h3 {
        min-height: 50px;

        @media(max-width: 991px) {
          min-height: auto;
        }
      }
    }
  }

  .features-section {
    padding: 75px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }

    p {
      @media(max-width: 991px) {
        font-size: 14px;
      }
    }

    article {
      h3 {
        min-height: 50px;

        @media(max-width: 991px) {
          min-height: auto;
        }
      }

      figure {
        width: 130px;
        height: 130px;
      }
    }
  }

  .opinions-section {
    padding: 75px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }

    p {
      @media(max-width: 991px) {
        font-size: 14px;
      }
    }

    .tns-nav {
      display: none;
    }

    .quote-img {
      width: 120px;
      transform: translateY(-70px);

      @media(max-width: 991px) {
        width: 80px;
        transform: none;
      }
    }
  }

  .quote-section {
    padding: 75px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }

    p {
      @media(max-width: 991px) {
        font-size: 14px;
      }
    }



  }

  .contact-section {
    padding: 75px 0 20px 0;
    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }

    p {
      @media(max-width: 991px) {
        font-size: 14px;
      }
    }

    .white-box {
      height: 85px;
      @media(max-width: 991px) {
        height: 60px;
      }
    }

    .phone-img {
      width: 120px;

      @media(max-width: 991px) {
        width: 80px;
      }
    }
  }
}
