@import './libs.scss';
@import './global.scss';
@import './buttons.scss';
@import './helpers.scss';
@import './colors.scss';
@import './typography.scss';
@import './animation.scss';

*{
  margin: 0;
  box-sizing: border-box;
}

html {
  margin-top: 0 !important;
}
